// portfolio
.portfolio-section {
    padding: 20px 0;
	min-height: 100vh;
	::-webkit-scrollbar {
		height: 0;
	}
	&__content {
		position: relative;
		justify-content: space-between;
		width: 100%;
		background-color: transparent;
        height: 70vh;
		// grid
		&--grid {
			justify-content: space-between;
			width: 100%;
			height: 100%;
			padding-bottom: 10px;
			overflow-x: scroll;
		}
		.grid-nav {
			position: absolute;
			display: flex;
			top: -44px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			animation: grid-btn 1000ms ease-in-out forwards 300ms;
			@include mobile-breakpoint {
				width: 100%;
			}
			li {
				position: relative;
				font-size: 18px;
				text-transform: uppercase;
				font-weight: 600;
				margin: 0 22px;
				color: $bl-secondary;
				cursor: pointer;
				@include mobile-breakpoint {
					font-size: 14px;
					font-weight: 400;
				}
			}
			.active {
				&::after {
					position: absolute;
                    content: "";
                    top: 20px;
                    left: -11%;
                    width: 120%;
                    height: 3px;
                    border-radius: 3px;
                    background-color: rgba($bl-secondary, 1);
                    z-index: -1;
                    transition: $short-animation;
                    transform-origin: center;
                    @include mobile-breakpoint {
                        height: 2px;
                    }
				}
			}
		}
		.illustration-portfolio-grid {
			grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			width: 4696px;
		}
		.storyboard-portfolio-grid {
			grid-template-columns: repeat(8, 1fr);
			grid-template-rows: repeat(3, 1fr);
		}
		.motiondesign-portfolio-grid {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}
		.div1 { grid-area: 1 / 1 / 2 / 3; }
		.div2 { grid-area: 2 / 1 / 3 / 3; }
		.div3 { grid-area: 3 / 1 / 4 / 3; }
		.div4 { grid-area: 1 / 3 / 3 / 5; }
		.div5 { grid-area: 3 / 3 / 4 / 5; }
		.div6 { grid-area: 2 / 5 / 4 / 7; }
		.div7 { grid-area: 1 / 5 / 2 / 7; }
		.div8 { grid-area: 1 / 7 / 2 / 9; }
		.div9 { grid-area: 2 / 7 / 3 / 9; }
		.div10 { grid-area: 3 / 7 / 4 / 9; }

		
		.item-33 { grid-area: 1 / 1 / 3 / 2; }
		.item-34 { grid-area: 1 / 2 / 3 / 3; }

		.portfolio-grid {
			display: grid;
			overflow-x: hidden;
			gap: 5px;
			height: 100%;
			.grid-item {
				width: 100%;
				height: 100%;
				overflow: hidden;
				animation: pictures-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
				opacity: 0;
				.portoflio-illustration {
					width: 100%;
					height: 100%;
					object-fit: cover;
					cursor: pointer;
					transition: $short-animation;
					&:hover {
						// transform: scale(1.05);
						filter: brightness(0.75);
					}
				}
			}
		}
		#item-0 {
			grid-row-start: 1;
			grid-column-start: 1;

			grid-row-end: 2;
			grid-column-end: 2;
		}
		#item-1 {
			grid-row-start: 1;
			grid-column-start: 3;

			grid-row-end: 3;
			grid-column-end: 4;
		}
		#item-2 {
			grid-row-start: 2;
			grid-column-start: 1;

			grid-row-end: 3;
			grid-column-end: 2;
		}
		#item-3 {
			grid-row-start: 1;
			grid-column-start: 2;

			grid-row-end: 4;
			grid-column-end: 3;
		}
		#item-4 {
			grid-row-start: 3;
			grid-column-start: 1;

			grid-row-end: 4;
			grid-column-end: 2;
		}
		#item-5 {
			grid-row-start: 3;
			grid-column-start: 3;

			grid-row-end: 4;
			grid-column-end: 5;
		}
		#item-5bis {
			grid-row-start: 3;
			grid-column-start: 5;

			grid-row-end: 3;
			grid-column-end: 5;
		}
		#item-6 {
			grid-row-start: 1;
			grid-column-start: 4;

			grid-row-end: 3;
			grid-column-end: 6;
		}
		#item-7 {
			grid-row-start: 1;
			grid-column-start: 6;

			grid-row-end: 1;
			grid-column-end: 8;
		}
		#item-8 {
			grid-row-start: 2;
			grid-column-start: 6;

			grid-row-end: 2;
			grid-column-end: 7;
		}
		#item-9 {
			grid-row-start: 2;
			grid-column-start: 7;

			grid-row-end: 2;
			grid-column-end: 8;
		}
		#item-10 {
			grid-row-start: 3;
			grid-column-start: 6;

			grid-row-end: 3;
			grid-column-end: 7;
		}
		#item-11 {
			grid-row-start: 3;
			grid-column-start: 7;

			grid-row-end: 3;
			grid-column-end: 8;
		}
		#item-12 {
			grid-row-start: 1;
			grid-column-start: 8;

			grid-row-end: 1;
			grid-column-end: 9;
		}
		#item-13 {
			grid-row-start: 1;
			grid-column-start: 9;

			grid-row-end: 1;
			grid-column-end: 10;
		}
		#item-14 {
			grid-row-start: 2;
			grid-column-start: 8;

			grid-row-end: 4;
			grid-column-end: 10;
		}
		#item-15 {
			grid-row-start: 1;
			grid-column-start: 10;

			grid-row-end: 1;
			grid-column-end: 10;
		}
		#item-16 {
			grid-row-start: 2;
			grid-column-start: 10;

			grid-row-end: 4;
			grid-column-end: 10;
		}
		#item-17 {
			grid-row-start: 1;
			grid-column-start: 11;

			grid-row-end: 3;
			grid-column-end: 13;
		}
		#item-18 {
			grid-row-start: 1;
			grid-column-start: 13;

			grid-row-end: 1;
			grid-column-end: 15;
		}
		#item-19 {
			grid-row-start: 2;
			grid-column-start: 14;

			grid-row-end: 2;
			grid-column-end: 15;
		}
		#item-20 {
			grid-row-start: 3;
			grid-column-start: 14;

			grid-row-end: 3;
			grid-column-end: 15;
		}
		#item-99 {
			grid-row-start: 2;
			grid-column-start: 13;
			grid-row-end: 4;
			grid-column-end: 13;
		}
		#item-97 {
			grid-row-start: 3;
			grid-column-start: 11;
			grid-row-end: 4;
			grid-column-end: 11;
		}
		#item-98 {
			grid-row-start: 3;
			grid-column-start: 12;
			grid-row-end: 4;
			grid-column-end: 12;
		}
		#item-21 {
			grid-row-start: 2;
			grid-column-start: 15;
			grid-row-end: 4;
			grid-column-end: 17;
		}
		#item-22 {
			grid-row-start: 1;
			grid-column-start: 15;
			grid-row-end: 2;
			grid-column-end: 15;
		}
		#item-23 {
			grid-row-start: 1;
			grid-column-start: 16;
			grid-row-end: 2;
			grid-column-end: 16;
		}



		#item-33 {
			grid-row-start: 1;
			grid-column-start: 27;
			grid-row-end: 4;
			grid-column-end: 30;
		}
		#item-34 {
			grid-row-start: 1;
			grid-column-start: 30;
			grid-row-end: 4;
			grid-column-end: 33;
		}
	}
	// grid button
	.slide-button {
		position: absolute;
		bottom: 10%;
        padding: 0 10px;
		background-color: transparent;
		color: white;
		font-size: 2.8em;
		font-weight: 100;
		cursor: pointer;
        border-radius: 4px;
		transition: $long-animation;
		transform: scaleX(2);
        background-color: rgba(255,255,255,0.4);
		@include tablet-breakpoint {
			font-size: 2em;
		}
		@include mobile-breakpoint {
			display: none;
			}
	}
	.slide-right {
		right: 15%;
		opacity: 0;
		animation: grid-btn 1000ms ease-in-out forwards 300ms;
		&:hover {
			transform: translateX(10px) scaleX(2);
		}
	}
	.slide-left {
		left: 15%;
		opacity: 0;
		animation: grid-btn 1000ms ease-in-out forwards 500ms;
		&:hover {
			transform: translateX(-10px) scaleX(2);
		}
	}
}

@keyframes pictures-fade-in {
	0% {
		opacity: 0;
		transform: translateX(30%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes grid-btn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


// full pics screen
.full-screen-container {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	cursor: pointer;
	transform: translateX(0);
	animation: open-full-screen 500ms cubic-bezier(0.66, 0, 0.3, 0.98);
	&__content {
		position: relative;
		width: 80%;
		height: 80%;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		animation: img-appaer 500ms cubic-bezier(0.66, 0, 0.3, 0.98) 100ms;
	}
}

@keyframes open-full-screen {
	from {
		transform: translateX(-100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes close-full-screen {
	from {
		transform: translateX(0);
		opacity: 1;
	}
	to {
		transform: translateX(-100%);
		opacity: 0;
	}
}

@keyframes img-appaer {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes img-disappaer {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(100%);
	}
}
