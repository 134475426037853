.contact-section {
	padding: 50px 0;
	min-height: 100vh;
	&__content {
		width: 90%;
		max-width: 1300px;
		height: 75%;
		display: flex;
		justify-content: space-between;
		margin: 40px auto 40px;
		// background-color: aqua;
		@include mobile-breakpoint {
			flex-direction: column-reverse;
		}
		&--left-part {
			position: relative;
			width: 30%;
			height: 100%;
			margin-top: 10px;
			.item {
				display: flex;
				align-items: center;
				margin-bottom: 35px;
				opacity: 0;
				animation: coordinates-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
				img {
					width: 36px;
					height: 36px;
					object-fit: contain;
					margin-right: 10px;
					filter: invert(14%) sepia(16%) saturate(6670%) hue-rotate(221deg) brightness(95%) contrast(98%);
				}
				p,
				a {
					color: $bl-secondary;
					font-size: 20px;
					font-weight: 500;
				}
			}
			.social-links {
				margin-top: 190px;
				@include mobile-breakpoint {
					margin-top: 80px;
					display: flex;
				}
				.social-link {
					opacity: 0;
					animation: social-links-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
				}
				img {
					width: 36px;
					height: 36px;
					object-fit: cover;
					filter: invert(14%) sepia(16%) saturate(6670%) hue-rotate(221deg) brightness(95%) contrast(98%);
					margin-right: 40px;
				}
			}
		}
		&--right-part {
			width: 70%;
			height: 100%;
			@include mobile-breakpoint {
				width: 100%;
			}
			form {
				position: relative;
				margin: 0 auto;
				@include tablet-breakpoint {
					margin: 0 0 0 150px;
				}
				@include mobile-breakpoint {
					margin: 0 0 50px 0;
				}
				::-webkit-scrollbar {
					width: 4px;
				}
				::-webkit-scrollbar-track {
					background: transparent;
				}
				::-webkit-scrollbar-thumb {
					background: $bl-tertiary;
				}
				::-webkit-scrollbar-thumb:hover {
					background: $bl-quatrary;
				}
				label {
					position: relative;
					.error {
						position: absolute;
						bottom: 30px;
						left: 50%;
						transform: translateX(-50%);
						color: $bl-secondary;
					}
				}
				input[type="text"],
				input[type="email"],
				input[type="submit"],
				textarea {
					width: 100%;
					padding: 15px 16px;
					margin-bottom: 15px;
					border: 3px solid $bl-secondary;
					font-size: 20px;
					color: $bl-secondary;
					background-color: transparent;
					border-radius: 6px;
					resize: none;
					&:focus {
						border: 3px solid $bl-tertiary;
					}
					&::placeholder {
						color: rgba($bl-secondary, 0.8)
					}
					// &:invalid {
					// 	animation: input-error 0.2s ease-in-out 2;
					// }
					opacity: 0;
					animation: inputs-fade-in 2200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
				}
				input[type="text"],
				input[type="email"],
				input[type="submit"] {
					max-width: 400px;
				}
				textarea {
					height: 200px;
				}
				input[type="submit"] {
					display: block;
					width: 150px;
					margin: auto;
					cursor: pointer;
					transition: 100ms;
					&:focus {
						color: $bl-tertiary;
						border: 3px solid $bl-tertiary;
					}
					&:active {
						color: $bl-tertiary;
						border: 3px solid $bl-tertiary;
						transform: scale(0.98);
					}
				}
				.message-sent {
					position: absolute;
					bottom: 5px;
					left: 55%;
					opacity: 0;
					animation: paper-plane 3000ms ease-in-out forwards;
					img {
						width: 36px;
						height: 36px;
						filter: invert(52%) sepia(9%) saturate(2400%) hue-rotate(177deg) brightness(94%) contrast(89%);
					}
				}
			}
		}
	}
}

@keyframes coordinates-fade-in {
	0% {
		opacity: 0;
		transform: translateX(-200px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes social-links-fade-in {
	from {
		opacity: 0;
		transform: translateY(200px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes inputs-fade-in {
	0% {
		opacity: 0;
		transform: translateY(-200px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes paper-plane {
	0% {
		opacity: 0;
		transform: translateX(0);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateX(220px) rotate(220deg);
	}
}

// @keyframes input-error {
// 	0% {
// 		transform: translateX(0);
// 	}
// 	20% {
// 		transform: translateX(5px);
// 	}
// 	40% {
// 		transform: translateX(-5);
// 	}
// 	60% {
// 		transform: translateX(5px);
// 	}
// 	80% {
// 		transform: translateX(-5px);
// 	}
// 	100% {
// 		transform: translateX(0);
// 	}
// }
