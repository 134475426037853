// menu
.menu-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&__transformed {
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba($bl-secondary, 1) 0%, rgba(rgba($bl-quinary, 1), 1) 100%);
		animation: close-menu 500ms cubic-bezier(0.66, 0, 0.3, 0.98);
		transform: translateX(0);
	}
	&__to-transform {
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba($bl-secondary, 1) 0%, rgba($bl-quinary, 1) 100%);
		animation: open-menu 500ms cubic-bezier(0.66, 0, 0.3, 0.98);
		transform: translateX(100%);
	}
	&__nav {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		li a {
			position: relative;
			color: white;
			font-size: 4.8em;
			font-weight: 500;
			line-height: 1.8em;
			letter-spacing: 10px;
			user-select: none;
			@include mobile-breakpoint {
				font-size: 2.6em;
            }
			&::after {
				position: absolute;
				content: "";
				top: -20%;
				left: -12%;
				width: 120%;
				height: 120%;
				background-color: rgba($bl-tertiary, 0.7);
				transform: scaleX(0);
				z-index: -1;
				transition: $short-animation;
				transform-origin: center;
			}
			&:hover {
				&::after {
					transform: scaleX(1);
				}
			}
		}
		.links__transformed {
			animation: links-appaer 500ms cubic-bezier(0.66, 0, 0.3, 0.98) forwards;
			opacity: 0;
		}
		#homeLink {
			animation-delay: 200ms;
		}
		#portfolioLink {
			animation-delay: 300ms;
		}
		#aboutLink {
			animation-delay: 400ms;
		}
		#contactLink {
			animation-delay: 500ms;
		}
		.links__to-transform {
			animation: links-disappaer 500ms cubic-bezier(0.66, 0, 0.3, 0.98) 0;
		}
	}
}

@keyframes open-menu {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(100%);
	}
}
@keyframes close-menu {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
@keyframes links-appaer {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes links-disappaer {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(-100%);
	}
}
