.title-underline {
	position: relative;
    margin-bottom: 110px;
	@include mobile-breakpoint {
		margin-bottom: 80px;
	}
	&::after {
		position: absolute;
		content: "";
		display: block;
		width: 200px;
		height: 4px;
		left: 200px;
		top: 10px;
        background-color: $bl-secondary;
		border-radius: 10px;
		z-index: -1;
		opacity: 0;
		animation: underline-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1000ms;
		@include mobile-breakpoint {
			left: 100px;
			height: 3px;
		}
	}
}

@keyframes underline-fade-in {
	0% {
		opacity: 0;
		transform: translateX(-30%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
