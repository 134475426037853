.about-section {
	padding: 50px 20px;
	min-height: 70vh;
	&__content {
		width: 90%;
		max-width: 1300px;
		height: 75%;
		display: flex;
		justify-content: space-between;
		margin: 40px auto 0;
		@include tablet-breakpoint {
			flex-direction: column-reverse;
		}
		@include mobile-breakpoint {
			margin: 0 auto;
		}
		&--skills-part {
			width: 30%;
			height: 100%;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			@include tablet-breakpoint {
				flex-direction: row;
				justify-content: space-around;
				width: 100%;
			}
			@include mobile-breakpoint {
				flex-direction: column;
            }
			.skills {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.skill {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 33%;
					margin-bottom: 40px;
					opacity: 0;
					animation: skill-pop-in $long-animation cubic-bezier(0, 0.74, 0.63, 1.48) forwards;
					img {
						width: 64px;
						height: 64px;
						object-fit: cover;
						filter: invert(14%) sepia(16%) saturate(6670%) hue-rotate(221deg) brightness(95%) contrast(98%);
						transition: $short-animation;
					}
				}
			}
			.hobbies {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 20px;
				color: $bl-secondary;
				@include tablet-breakpoint {
					margin-top: 0;
				}
				.hobby {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 50%;
					margin-bottom: 20px;
					opacity: 0;
					animation: hobbie-fade-in $long-animation ease-in-out forwards;
					img {
						width: 48px;
						height: 48px;
						object-fit: cover;
						margin-bottom: 20px;
						filter: invert(14%) sepia(16%) saturate(6670%) hue-rotate(221deg) brightness(95%) contrast(98%);
					}
					p {
						text-align: center;
						font-size: 20px;
						font-weight: 500;
					}
				}
				.project-counter {
					justify-content: space-between;
					h4 {
						font-size: 56px;
						font-weight: 300;
					}
				}
			}
		}
		&--character-part {
			position: relative;
			width: 70%;
			height: 100%;
			// .canard-head {
			// 	position: absolute;
			// 	top: -120px;
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	width: 96px;
			// 	height: 96px;
			// 	object-fit: cover;
			// 	border-radius: 50%;
			// }
			@include tablet-breakpoint {
				width: 88%;
			}
			@include mobile-breakpoint {
				width: 100%;
			}
			.header {
				@include tablet-breakpoint {
					display: none;
				}
			}
			.content {
				padding-left: 80px;
				@include mobile-breakpoint {
					padding-left: 0;
				}
				p {
					color: $bl-secondary;;
					font-size: 20px;
					text-align: justify;
					margin-bottom: 20px;
					opacity: 0;
					animation: text-fade-in $long-animation ease-in-out forwards;
					line-height: 1.15em;
					user-select: text;
					cursor: default;
					
					span {
						color: $bl-quatrary;
						font-weight: 600;
						
					}
				}
				.contact {
					margin-top: 40px;
				}
			}
		}
	}
}

@keyframes skill-pop-in {
	0% {
		opacity: 0;
		transform: scale(0.3);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes hobbie-fade-in {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes text-fade-in {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
