.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px 0 0;
    &__logo {
        margin-bottom: 20px;
        opacity: 0;
        animation: title-fade-in $long-animation ease-in-out forwards 1500ms;
        img {
            width: 350px;
            filter: invert(12%) sepia(89%) saturate(1288%) hue-rotate(220deg) brightness(102%) contrast(99%);
            @include tablet-breakpoint {
				width: 300px;
			}
            @include mobile-breakpoint {
				width: 200px;
			}
        }
    }
    &__separator {
        width: 150px;
        height: 3px;
        margin: 0 auto 30px;
        border-radius: 5px;
        background-color: $bl-secondary;
        opacity: 0;
        animation: title-fade-in $long-animation ease-in-out forwards 1600ms;
    }
    &__nav ul {
        display: flex;
        justify-content: center;
        li {
            width: 130px;
            text-transform: uppercase;
            opacity: 0;
            animation: navbar-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 2000ms;
            
            a {
                position: relative;
                color: $bl-secondary;
                @include mobile-breakpoint {
                    font-size: 14px;
                }
                &::after {
                    position: absolute;
                    content: "";
                    top: 20px;
                    left: -11%;
                    width: 120%;
                    height: 3px;
                    border-radius: 3px;
                    background-color: rgba($bl-secondary, 1);
                    transform: scaleX(0);
                    z-index: -1;
                    transition: $short-animation;
                    transform-origin: center;
                    @include mobile-breakpoint {
                        height: 2px;
                    }
                }
                &:hover {
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
    &__illustration {
        width: 100%;
        height: 73vh;
        margin-bottom: 60px;
        // .skills {
        //     position: absolute;
        //     text-align: left;
        //     top: 550px;
        //     left: 40px;
        //     color: $bl-white;
        //     font-size: 1.6rem;
        //     letter-spacing: 2px;
        //     line-height: 1.5em;
        //     margin: 7% 0 0 10px;
        //     .skill {
        //         animation: skills-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
        //         opacity: 0;
        //     }
        // }
        // #skillsOne {
        //     animation-delay: 1500ms;
        // }
        // #skillsTwo {
        //     animation-delay: 2000ms;
        // }
        // #skillsThree {
        //     animation-delay: 2500ms;
        // }
        .big-wale-container {
            position: absolute;
            top: 220px;
            left: 0;
            width: 100%;
            height: 65vh;
            overflow: hidden;
            background: linear-gradient(0deg, rgba(21, 21, 75, 1) 0%, rgba(37, 46, 111, 1) 100%);
            opacity: 0;
            animation: big-whale-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1000ms;
            z-index: -9;
            @include mobile-breakpoint {
                top: 190px;
                height: 65vh;
            }
            .corrector {
                position: absolute;
                top: -8px;
                left: 0;
                width: 100%;
                height: 100%;
                transform: scale(1.02);
                img {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: $short-animation ease-out;
                }
                #bigWaleCMove {
                    position: absolute;
                    bottom: 40px;
                    right: -250px;
                    width: 100%;
                    height: 100%;
                    animation: cloud-movement 40s linear infinite;
                    transform: scale(0.6);
                    background: url(../assets/big-waleCMove.png);
                    opacity: 0;
                    animation: big-whale-fade-in 2000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 3000ms;
                }
            }
        }
    }
}

@keyframes cloud-movement {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: 2500px;
	}
}

@keyframes title-fade-in {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes navbar-fade-in {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes skills-fade-in {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes big-whale-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}